import "./src/styles/global.less"

import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

// eslint-disable-next-line import/prefer-default-export
export const wrapPageElement = ({ element }) => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            {element}
        </QueryClientProvider>
    );
};
